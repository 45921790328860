/* eslint eqeqeq: "off", curly: "error" */
import React, { useEffect, useState } from 'react';

import Search from '../../../../components/Search';
import { PatientName } from '../../../../components/PatientName';
import { danger, info, warning } from '../../../../components/Alerts';
import { getEvolution, getPatientsWithSector } from '../../../../utils/api';
import { Table } from '../../../../components/Table';
import { Button } from '../../../../styles/Button';
import { useNavigate } from 'react-router';
import { GlobalContext } from '../../../../GlobalContext';

export default function ListEvolutions() {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState('');
  const [current, setCurrent] = useState('');
  const [evolutions, setEvolutions] = useState([]);
  const navigate = useNavigate();
  const [patientInfos, setPatientInfos] = useState({});
  const { actions, currentUser } = React.useContext(GlobalContext);

  function handleGetPatientInfos() {
    const data = patient.split('-');
    getEvolution(data[0], data[1]).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        setCurrent(patient);
        const patientData = patients.find(patient => patient.id == data[0] && patient.year == data[1]);
        setPatientInfos(patientData);
        !patientData.active && warning('Paciente inativo!');

        let array = [];
        if (
          !json.data.arrPacienteAcompanhamento ||
          json.data.arrPacienteAcompanhamento.PA_ACOMPANHAMENTOS.length < 1
        ) {
          info('O paciente não tem evoluções cadastradas!');
        } else {
          json.data.arrPacienteAcompanhamento.PA_ACOMPANHAMENTOS.forEach(evolution => {
            array.push({
              date: evolution.PA_DATA,
              realDate: evolution.PA_DATSYS,
              realHour: evolution.PA_HORSYS,
              hour: evolution.PA_HORA,
              label: evolution.PA_EVOLUCAO,
              responsible: evolution.QRY_RESPONSAVEL,
              responsibleId: evolution.PA_RESPONSAVEL,
              sector: evolution.QRY_SETOR,
              procedures: evolution.PA_PROCEDIMENTOS.map(
                procedimento => procedimento.PAP_PROCEDIMENTO
              ),
              status: evolution.PA_SITUACAO
            });
          });
          setEvolutions(array);
        }
      }
    });
  }

  useEffect(() => {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          const patientObj = {
            id: patient.PAC_CODIGO,
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            cpf: patient.PAC_NUMR_CPF,
          };
          arr.push(patientObj);
        });
        setPatients(arr);
        arr[0]
          ? setPatient(`${arr[0].id}-${arr[0].year}`)
          : info('Ainda não foi cadastrado nenhum paciente!');
      }
    });
  }, []);

  useEffect(() => {
    setEvolutions([]);
    setCurrent('');
  }, [patient]);

  return (
    <div className="animeUp">
      <h2 className="section">Evoluções</h2>

      <Search
        data={patients}
        value={patient}
        setValue={setPatient}
        search={handleGetPatientInfos}
        placeholder="pacientes"
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {patientInfos.id && <PatientName patientInfos={patientInfos} />}

        {evolutions.length > 0 ? (
          <div className="animeUp">
            {actions.some(action => action.id === 501) && patientInfos.active && (
              <Button primary onClick={() => navigate(`new/${current}`)}>
                Nova Evolução
              </Button>
            )}
            <Table
              content={evolutions}
              setContent={setEvolutions}
              id={current}
              headers={
                actions.some(action => action.id === 1033)
                  ? {
                      date: currentUser.sector.value == 3 ? 'Data/Hora' : 'Data',
                      realDate: 'Data Real',
                      description: 'Descrição',
                      sector: 'Área',
                      responsible: 'Responsável'
                    }
                  : {
                      date: currentUser.sector.value == 3 ? 'Data/Hora' : 'Data',
                      description: 'Descrição',
                      sector: 'Área',
                      responsible: 'Responsável'
                    }
              }
              buttons={{ edit: actions.some(action => action.id === 502), delete: true }}
              typeTable="evolutions"
              patient={patient}
            />
          </div>
        ) : (
          current &&
          actions.some(action => action.id === 501) && (
            <Button
              primary
              style={{ marginRight: 'auto' }}
              className="animeLeft"
              onClick={() => navigate(`new/${current}`)}
            >
              Nova Evolução
            </Button>
          )
        )}
      </div>
    </div>
  );
}
